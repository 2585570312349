@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1080px;
    color: white;
    background: url("../../../assets/svg/IntroductionBackground.svg") no-repeat;
    background-size: cover;
    background-position: bottom;
    overflow: hidden;

    .text_container {
        @include flex-column-center;
        height: 100%;
        gap: 30px;
        user-select: none;
    }

    .main_title {
        color: #ffffff;
        text-align: center;
        font-size: 85px;
        font-weight: 700;

        .line_break {
            display: none;
        }
    }

    .content {
        color: #ffffff;
        text-align: center;
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;

        .line_break {
            display: none;
        }
    }
}

@media (max-width: 1024px){
    .section_container {

        .main_title {

            .line_break {
                display: inline;
            }
        }

        .content {
            line-height: 45px;
    
            .line_break {
                display: inline;
            }
        }
    }
}

@media (max-width: 768px) {
    .section_container {
        height: 812px;

        .main_title {
            font-size: 36px;
            line-height: 45px;
        }

        .content {
            font-size: 16px;
            line-height: 29px;
        }
    }
}