@import "/src/color.scss";
@import "/src/common.scss";

.box {
  @include justi-between;
  border-top: 1px solid $box-gray;
  border-bottom: 1px solid $box-gray;
  padding: 50px 0;

  div {
    box-sizing: border-box;
  }

  .between {
    @include justi-center;
    gap: 10px;
    margin-top: 20px;
    color: $deco-gray;
    font-size: 14px;

    span {
      display: flex;
      gap: 4px;

      div {
        width: 16px;
        height: 16px;
        border-radius: 6px;
      }
    }

    .blue_circle {
      background-color: $light-blue;
    }

    .lilac_circle {
      background-color: $lilac;
    }
  }

  .desc {
    width: 850px;
    line-height: 26px;
    font-size: 15px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #323842;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      margin-top: 20px;
    }
  }
}

@media (max-width: 768px) {
  .box {
    @include flex-column;
    padding: 20px 0;

    div {
      @include flex-column-center;

      img {
        width: 200px;
      }

      .between {
        display: flex;
        flex-direction: row;
      }
    }

    .desc {
      width: 281px;
      align-items: flex-start;
      line-height: 20px;

      h3 {
        font-size: 15px;
        margin-top: 20px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}
