@import "/src/color.scss";
@import "/src/common.scss";

.section {
  @include flex-column;
  margin-top: 50px;
  overflow: hidden;

  h1 {
    font-size: 30px;
  }

  .info_box {
    display: flex;
    gap: 60px;
    margin-top: 20px;

    .image_container {
      position: relative; /* 부모 요소에 relative 설정 */
      display: inline-block;

      img {
        width: 380px;
        border-top-right-radius: 60px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      .circle_icon {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 80px;
        height: 80px;
      }

      .ranking_number {
        position: absolute;
        top: 35px;
        left: 36px;
        font-size: 17px;
        color: white;
        font-weight: bold;
        z-index: 2;
      }
    }

    .loading_section {
      table {
        width: 740px;
      }

      .loading {
        @include flex-column-center;
        width: 100%;
        height: 90%;

        .box {
          @include justi-align-center;
          margin-top: 10px;

          .spinner {
            color: #feba69;
            width: 30px;
            animation: spin 1s ease-in-out infinite;
          }

          .desc {
            color: $black;
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }

    table {
      border-collapse: collapse;
      text-align: center;
      font-size: 17px;
      width: 100%;

      th {
        border-top: 3px solid $black;
        border-bottom: 1px solid $border-gray;
        padding: 16px;
        font-weight: 700;
      }

      tbody > tr {
        &:hover {
          background-color: $light-gray;
          cursor: pointer;
        }
      }

      td {
        border-bottom: 1px solid $border-gray;

        &:nth-child(1) {
          text-align: left;
          font-weight: 700;
          cursor: pointer;
        }

        .title_text {
          display: flex;
          gap: 10px;

          .text {
            width: 350px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .selected_row {
        background-color: $light-orange;
      }

      .selected_title {
        color: $active-orange;
      }

      .number_circle {
        width: 24px;
        height: 24px;
        @include justi-align-center;
        border-radius: 10px;
        color: white;
        background-color: $text-gray;
        font-size: 13px;
        font-weight: 400;

        &.active {
          background-color: $active-orange;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section {
    margin-top: 40px;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scroll-gray;
      border-radius: unset;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: $box-gray;
      border-radius: unset;
    }

    h1 {
      font-size: 20px;
    }

    .info_box {
      @include flex-column;
      gap: 24px;

      .image_container {
        position: relative;
        width: 100%;
      }

      img {
        width: 280px !important;
      }

      .circle_icon {
        width: 60px;
        height: 60px;
        top: 5px !important;
        left: 0px !important;
      }

      .ranking_number {
        font-size: 17px !important;
        top: 30px !important;
        left: 27px !important;
      }

      .loading {
        margin-top: 50px;
      }

      table {
        font-size: 13px;
        white-space: nowrap;
        overflow: auto;

        td {
          padding: 12px;

          &:nth-child(1) {
            font-weight: 700;
            cursor: pointer;
          }

          .title_text {
            @include align-center;

            .text {
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
