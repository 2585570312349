@import "/src/common.scss";
@import "/src/color.scss";

@mixin nav_container_default {
    @include flex-column;
    position: fixed;
    width: 100%;
    z-index: 10;
    transition-duration: 0.7s;
}

@mixin nav_menu_default {
    display: flex;
    gap: 100px;

    div {
        font-size: 17px;
        line-height: 22px;
        transition-duration: 0.7s;
        cursor: pointer;
    }
}

.nav_container_light {
    @include nav_container_default;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    background: transparent
}

.nav_container_dark {
    @include nav_container_default;
    border-bottom: 1px solid $border-gray;
    background: #ffffff;
}

.user {
    width: 30px;
    cursor: pointer;
}

.nav_menu_dark {
    @include nav_menu_default;

    div {
        color: $black;
    }
}

.nav_menu_light {
    @include nav_menu_default;

    div {
        color: #ffffff;
    }
}

.logo {
    cursor: pointer;
}

.nav {
    @include justi-between-align-center;
    width: 100%;
    padding: 20px 100px;
    box-sizing: border-box;
    background-color: transparent;
    color: #ffffff;
}

@media (max-width: 1024px) {
    .nav_menu_dark, .nav_menu_light {
        display: none;
    }

    .nav {
        padding: 10px 20px;
    }
}