body {
  margin: 0;
  font-family: "Pretendard";
  font-weight: 400;
  font-style: normal;

  p,
  h1,
  h2,
  h3 {
    margin: 0;
  }
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/Pretendard-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/Pretendard-Medium.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Pretendard";
  src: url(./assets/font/Pretendard-Regular.otf);
  font-weight: 400;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
