@import "/src/color.scss";
@import "/src/common.scss";

.modal_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
}

dialog {
  @include flex-column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1300px;
  height: auto;
  max-height: 900px;
  color: $very-dark-gray;
  padding: 50px 40px;
  border: none;
  outline: none;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 10;

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scroll-gray;
      border-radius: 10px;
      height: 40px;
      max-height: 40px;
    }

    &::-webkit-scrollbar-track {
      background: $box-gray;
      border-radius: 10px;
    }
  }

  .between {
    @include justi-between;

    p {
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 700;

      .next {
        display: block;
        font-size: 13px;
        font-weight: 300;
      }
    }

    .cancle_btn {
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  dialog {
    // width: 100%;
    max-width: 340px;
    height: auto;
    max-height: 98vh;
    padding: 50px 10px 50px 24px;
    margin-top: 30px;

    .content {
      padding-right: 15px;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: unset;
      }

      &::-webkit-scrollbar-track {
        border-radius: unset;
      }

      .between {
        p {
          font-size: 15px;
        }
      }
    }
  }
}
