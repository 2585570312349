@import "/src/color.scss";
@import "/src/common.scss";

.section {
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin: 50px 0 20px;
  }
}

@media (max-width: 768px) {
  .section {
    .title {
      font-size: 17px;
      margin: 50px 0 20px;
    }
  }
}
