@import "/src/common.scss";
@import "/src/color.scss";

@mixin value_default {
  @include flex-column-center;
  width: 100%;
  height: 100%;
  gap: 5px;
  padding: 20px 10px;
  box-sizing: border-box;
  background: $navy-gray;
  backdrop-filter: blur(5px);

  span {
    color: rgba(255, 255, 255, 0.7);
    font-size: 15px;
  }

  p {
    color: #ffffff;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.card_container {
  @include flex-column;
  aspect-ratio: 424 / 580;
  width: 100%;
  max-height: 620px;
  border-top-left-radius: 120px;
  cursor: pointer;

  .image {
    @include flex-column;
    flex-grow: 3;
    flex-shrink: 0;
    overflow: hidden;

    img {
      aspect-ratio: 424 / 334;
      display: flex;
      height: 100%;
      border-top-left-radius: 120px;
      background-color: #111;
      object-fit: cover;
      object-position: top;
    }
  }

  .title {
    @include flex-column-center;
    flex-grow: 2;
    flex-shrink: 0;
    width: 100%;
    gap: 20px;
    padding: 30px;
    box-sizing: border-box;
    background-color: #111;
    text-align: center;

    p:nth-child(1) {
      width: 100%;
      max-width: 350px;
      color: #ffffff;
      font-size: 20px;
      font-weight: 700;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    p:nth-child(2) {
      width: 100%;
      color: rgba(255, 255, 255, 0.7);
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .result {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;

    div {
      @include value_default;
    }

    div:nth-child(2),
    div:nth-child(3) {
      border-left: 1px solid $dark-gray;
    }
  }
}

.per {
  @include align-center;
  width: 100%;
  height: inherit;
  gap: 10px;

  .bar {
    position: relative;
    width: calc(100% - 34px);
    height: 10px;
    border-radius: 10px;
    background: $very-dark-gray;

    .progress {
      height: 10px;
      max-width: 100%;
      border-radius: 10px;
      background: $linear-gradient;
    }
  }

  .percentage {
    width: 98px;
    background: $linear-gradient;
    background-clip: text;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
}

@media (max-width: 768px) {
  .card_container {
    border-top-left-radius: 15vw;

    .image {
      width: 100%;
      overflow: hidden;

      img {
        width: 100%;
        border-top-left-radius: 15vw;
      }
    }

    .title {
      gap: 10px;
      padding: 24px;

      span {
        font-size: 17px;
      }

      p {
        font-size: 13px;
      }
    }

    .result {
      div {
        span {
          font-size: 15px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }
}
