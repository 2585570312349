@import "/src/common.scss";
@import "/src/color.scss";

.title {
    @include align-center;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 0px 10px;
    box-sizing: border-box;

    div {
        @include justi-align-center;
        width: 24px;
        height: 24px;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #8A8A8A;
        color: #ffffff;
        text-align: center;
        font-size: 17px;
        font-weight: 400;
    }
    
    span {
        color: $black;
        text-align: center;
        font-size: 17px;
        font-weight: 700;
    }
}