@import "/src/color.scss";
@import "/src/common.scss";

.section {
  @include flex-column;
  margin-top: 50px;

  h3 {
    font-size: 24px;
    margin: 0px 0 20px;
  }
  .online_data {
    display: flex;
    gap: 20px;
  }
  .graph {
    border: 1px solid $box-gray;
    margin-bottom: 20px;
    padding: 20px;
  }

  .boxes {
    @include justi-between;
  }
}

@media (max-width: 768px) {
  .section {
    margin-top: 40px;

    .online_data {
      flex-direction: column;
      gap: 10px;
    }

    h2 {
      font-size: 20px;
      margin-top: 40px;

      .crown_img {
        width: 16px;
        height: 13px;
      }
    }

    h3 {
      font-size: 15px;
      margin: 40px 0 10px;
    }

    .graph {
      padding: 14px;
      margin-bottom: 10px;

      img {
        width: 253px;
        height: 90px;
      }
    }

    .boxes {
      @include flex-column;
      gap: 10px;
    }
  }
}
