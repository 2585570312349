@import "/src/color.scss";
@import "/src/common.scss";

.box {
  width: 350px;
  height: 125px;
  @include flex-column;
  padding: 24px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 10px;
  border: 1px solid $box-gray;

  .title {
    display: flex;
    gap: 6px;

    span {
      font-size: 15px;
      color: $text-gray;
      font-weight: 700;
    }
  }

  .amount {
    display: flex;
    flex-direction: row-reverse;
    font-size: 30px;
    font-family: "Poppins";
  }
}

@media (max-width: 768px) {
  .box {
    width: 241px;
    height: 86px;
    padding: 14px;

    .title {
      span {
        font-size: 13px;
      }
    }

    .amount {
      font-size: 20px;
    }
  }
}
