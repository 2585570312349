@import "/src/common.scss";
@import "/src/color.scss";

@mixin header_default{
    display: flex;
    width: 100%;
    height: 20%;
    padding: 50px 40px 30px;
    box-sizing: border-box;
    border-radius: 16px 16px 0px 0px;

    span {
        color: #ffffff;
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
    }

    @media screen and (max-width: 1440px) {
        height: 35%;
        padding: 50px 24px 24px 24px;
    }
}

.card_container {
    @include flex-column;
    width: 400px;
    height: 570px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: -6px 5px 10px 1px rgb(190, 190, 190);

    .gray {
        @include header_default;
        background: $dark-gray;
    }
    
    .orange_gradient {
        @include header_default;
        background: $linear-gradient;
    }
    
    .red_gradient {
        @include header_default;
        background: $linear-gradient-red;
    }
}

.contents {
    @include align-center;
    flex-direction: column;
    width: 100%;
    height:460px;
    gap: 50px;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 0px 0px 16px 16px;
    background-color: #ffffff;

    .button {
        @include justi-align-center;
        width: 44px;
        height: 44px;
        border: 1px solid #111;
        border-radius: 56px;
        background-color: transparent;
        cursor: pointer;
    }

    .plandetails {
        @include justi-between-align-center;
        width: 100%;
    
        span {
            color: $text-gray;
            font-size: 15px;
            font-weight: 500;
        }
    
        div {
            @include align-center;
            gap: 20px;
    
            span:nth-child(1) {
                color: $black;
                font-size: 24px;
                font-weight: 700;
            }
    
            span:nth-child(2) {
                @include justi-align-center;
                padding: 2px 10px;
                box-sizing: border-box;
                border-radius: 46px;
                background-color: $light-gray;
                color: $dark-gray;
                font-size: 15px;
                font-weight: 700;
                line-height: 26px;
            }
        }
    }

    .line {
        width: 100%;
        height: 1px;
        background: #DEE1E6;
    }
    
    .optionlist {
        @include flex-column;
        width: 100%;
        gap: 20px;
    
        span {
            color: $text-gray;
            font-size: 17px;
            font-weight: 700;
            line-height: 24px;
        }
    
        div {
            @include justi-between;
            width: 100%;
        }

        .warning {
            color: #D9D9D9;
        }
    }
}

@media (max-width: 1440px) {
    .card_container {
        width: 335px;
        height: 460px;
        border-radius: 16px;

        .contents {
            height: 80%;
            gap: 24px;
            padding: 24px;

            .plandetails {
                div {
                    gap: 10px;
                }
            }
        }
    }
}