@import "/src/common.scss";
@import "/src/color.scss";
.swiper {
  padding-bottom: 50px !important;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}
  
.swiper-scrollbar {
  width: calc(
    174px - 2 * var(--swiper-scrollbar-sides-offset, 1%)
  ) !important;
  height: 4px;
  bottom: 10px !important;
  border-radius: 10px;
  background-color: $scroll-gray !important;
  transform: translateX(-50%);
  z-index: 10;
}

div:nth-of-type(2) .swiper-scrollbar {
  left: 50% !important;
}

div:nth-of-type(7) .swiper {
  padding-bottom: 0px !important;
  overflow: hidden;
}

.swiper-scrollbar-drag {
  height: 100%;
  border-radius: 10px;
  background-color: $dark-orange !important;
}

@media screen and (max-width: 1024px) {
  .swiper-scrollbar {
    width: 90% !important;
    height: 4px;
    border-radius: 10px;
  }

  div:nth-of-type(2) .swiper-scrollbar {
    width: 90%;
  }

  .swiper-scrollbar-drag {
    height: 100%;
    border-radius: 10px;
    background-color: $dark-orange !important;
  }
}