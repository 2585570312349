@import "/src/common.scss";
@import "/src/color.scss";

.card_container {
  display: flex;
  width: 1200px;
  height: 950px;

  img {
    aspect-ratio: 335 / 223;
    width: 1080px;
    height: 720px;
    top: 0px;
    border: none;
    border-top-left-radius: 120px;
  }

  .information {
    @include flex-column;
    position: absolute;
    width: 1080px;
    height: 325px;
    right: 0px;
    bottom: 0px;
    gap: 24px;
    padding: 50px;
    box-sizing: border-box;
    border-top-right-radius: 120px;
    background-color: #ffffff;
    z-index: 3;

    .title {
      color: $black;
      font-size: 36px;
      font-weight: 800;
    }

    .content {
      max-height: 200px;
      color: rgba(23, 26, 31, 0.7);
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 7; /* 라인수 */
      -webkit-box-orient: vertical;
      word-wrap: break-word;
    }
  }
}

@media (max-width: 1300px) {
  .card_container {
    width: 90vw;
    height: 70vw;

    img {
      width: 80vw;
      height: 50vw;
      left: 0px;
    }

    .information {
      width: 80vw;
      right: 0px;

      .button {
        @include justi-align-center;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .card_container {
    width: 100%;
    height: 80vw;

    img {
      border-top-left-radius: 10vw;
    }

    .information {
      @include justi-center;
      width: 75vw;
      height: 35vw;
      padding: 24px;
      border-top-right-radius: 10vw;

      .content {
        max-height: 50%;
      }
    }
  }
}

@media (max-width: 768px) {
  .card_container {
    .information {
      .title {
        font-size: 20px;
      }

      .content {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .card_container {
    height: 120vw;

    img {
      width: 100%;
    }

    .information {
      @include justi-center;
      width: 70vw;
      height: 80vw;
      min-height: 200px;
      border-top-right-radius: 10vw;

      .content {
        min-height: 140px;
      }
    }
  }
}
