@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
    @include flex-column-center;
    flex-shrink: 0;
    width: 100%;
    height: inherit;
    padding: 130px 100px;
    box-sizing: border-box;
    background: url("../../../assets/svg/aiBackground.svg") no-repeat;
    background-size: cover;
    background-position: bottom left;
    overflow: hidden;

    .title_area {

        .title {
            margin-bottom: 30px;
            background: $linear-gradient;
            background-clip: text;
            text-align: center;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 800;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            user-select: none;
        }
    
        .subtitle {
            margin-bottom: 50px;
            color: $black;
            font-size: 60px;
            font-weight: 800;
            text-align: center;
            user-select: none;
    
            .line_break {
                display: none;
            }
        }
    }
    
    .swiper_container_ai {
        @include align-center;
        width: inherit;
        height: inherit;
        max-width: 1700px;

        .loading {
            @include justi-align-center;
            aspect-ratio: 400/600;
            width: 100%;
            gap: 10px;
            background-color: rgba(0, 0, 0, 0.5);

            .spinner {
                width: 30px;
                color: #ffffff;
                animation: spin 1s ease-in-out infinite;
            }

            span {
                color: #ffffff;
                font-family: Poppins;
                font-size: 20px;
            }

            @keyframes spin {
                100% {
                  transform: rotate(360deg);
                }
              }
        }
    }

    .analysis {
        @include flex-column-center;
        gap: 24px;
        padding: 30px;
        box-sizing: border-box;
    }
}

@media (max-width: 1400px){
    .section_container {

        .swiper_container_ai {
            width: 850px;
        }
    }
}

@media (max-width: 1024px){
    .section_container {

        .swiper_container_ai {
            width: 90%;
        }

        .title_area {

            .subtitle {

                .line_break {
                    display: inline;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .section_container {
        gap: 25px;
        padding: 60px 0px;

        .swiper_container_ai {
            min-width: 350px;
        }

        .title_area {
            gap: 15px;

            .title {
                margin-bottom: 15px;
                font-size: 15px;
            }
    
            .subtitle {
                margin-bottom: 0px;
                font-size: 30px;
            }
        }
    }
}