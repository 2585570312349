@import "/src/common.scss";
@import "/src/color.scss";

.card_container {
  @include flex-column-center;
  aspect-ratio: 400/600;
  transition-duration: 1s;

  .contents {
    display: flex;
    height: 100%;
    width: 100%;

    .aidata {
      aspect-ratio: 400/600;
      display: flex;
      position: absolute;
      width: 100%;
      gap: 24px;
      padding: 30px;
      box-sizing: border-box;
      background: transparent;
      transition-duration: 1s;
      z-index: 1;

      .gpt_predict {
        display: none;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        div {
          @include align-center;
          gap: 10px;
          padding: 6px 10px;
          box-sizing: border-box;
          border-radius: 14px;
          color: #ffffff;
          font-size: 15px;
          background: rgba(255, 255, 255, 0.2);
        }

        span {
          width: 100%;
          color: #ffffff;
          font-size: 17px;
          font-weight: 700;
        }
      }

      .gpt_analysis {
        display: none;
        width: 100%;
        flex-direction: column;
        gap: 10px;

        span {
          display: flex;
          width: 100%;
          color: #ffffff;
          font-size: 17px;
          font-weight: 700;
        }

        p {
          display: flex;
          width: 100%;
          color: rgba(255, 255, 255, 0.7);
          font-size: 15px;
          font-weight: 400;
        }
      }

      .movie_title {
        display: none;
        width: 100%;
        color: #ffffff;
        font-size: 24px;
        font-weight: 900;
      }

      .button {
        display: none;
      }

      &:hover {
        @include justi-align-center;
        flex-direction: column;
        gap: 24px;
        border-bottom-left-radius: 120px;
        background: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(10px);
        z-index: 3;

        * {
          display: flex;
        }
      }

      &:hover ~ .default .image {
        border-bottom-left-radius: 120px;
      }

      &:hover ~ .default .default_title {
        border-bottom-left-radius: 120px;
        background: transparent;
        backdrop-filter: blur(0px);

        * {
          color: transparent;
        }
      }
    }

    .default {
      width: 100%;
      height: 100%;

      .default_title {
        @include flex-column-center;
        position: absolute;
        width: 100%;
        height: 117px;
        bottom: 0;
        gap: 10px;
        padding: 30px;
        box-sizing: border-box;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
        transition-duration: 1s;

        span {
          color: #ffffff;
          font-size: 24px;
          font-weight: 700;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        p {
          color: rgba(255, 255, 255, 0.7);

          b {
            color: #ffffff;
            font-size: 15px;
          }
        }
      }

      .image {
        aspect-ratio: 400/600;
        display: flex;
        width: 100%;
        transition-duration: 1s;
        box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

@media (max-width: 1024px) {
  .card_container {
    width: 100%;
    height: 100%;

    .contents {
      display: flex;
      width: 100%;
      height: 100%;

      .aidata {
        width: 100%;
        height: 100%;

        .movie_title {
          font-size: 20px;
        }

        .gpt_predict {
          span {
            font-size: 15px;
          }
        }

        .gpt_analysis {
          span {
            font-size: 15px;
          }

          p {
            display: -webkit-box;
            font-size: 13px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: keep-all;
          }
        }

        &:hover {
          border-bottom-left-radius: 20vw;
        }

        &:hover ~ .default .image {
          border-bottom-left-radius: 20vw;
        }

        &:hover ~ .default .default_title {
          border-bottom-left-radius: 20vw;
        }
      }
    }
  }

  .default {
    .default_title {
      width: 100%;
      min-height: 50px;
    }
  }
}
