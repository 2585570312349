@import "/src/common.scss";
@import "/src/color.scss";

.table_container {
    display: flex;
    flex-direction: column;
    width: inherit;
    height: inherit;
    gap: 10px;

    .title {
        @include align-center;
        justify-content: flex-start;
        width: 100%;
        gap: 10px;

        .grade {
            @include justi-align-center;
            width: 24px;
            height: 24px;
            border-radius: 10px;
            background-color: $text-gray;
            color: #ffffff;
            font-size: 13px;
        }

        .movie_title {
            @include align-center;
            height: 100%;
            color: $black;
            font-size: 17px;
            font-weight: 700;
            text-align: left;
        }
    }

    .table_item {
        @include flex-column-center;
        width: 335px;
        height: 264px;
        padding: 24px;
        box-sizing: border-box;
        border: 1px solid $border-gray;
        border-radius: 14px;
        background-color: #ffffff;

        &:hover {
            background: $light-gray;
        }
    }

    .line {
        width: 100%;
        height: 1px;
        margin-bottom: 14px;
        background-color: $border-gray;
    }

    table {
        width: 100%;
        border-spacing: 0px 14px;

        th {
            font-size: 13px;
            font-weight: 700;
            text-align: left;
        }

        td {
            font-size: 13px;
        }
    }
}

