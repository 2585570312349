#chartId {
  position: relative;
  width: 100%;
  height: 200px;

  /* 모바일 화면에서 차트를 오른쪽으로 이동 */
  @media (max-width: 768px) {
    transform: translateX(30px); /* 필요에 따라 X축 이동 조정 */
  }
}
