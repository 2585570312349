@import "/src/color.scss";
@import "/src/common.scss";

.modal_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
}

dialog {
  @include flex-column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1300px;
  height: auto;
  max-height: 900px;
  color: $very-dark-gray;
  padding: 50px 20px 50px 40px;
  border: none;
  outline: none;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
  z-index: 10;
  background: #fff;

  .content {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $scroll-gray;
      border-radius: 10px;
      height: 40px;
      max-height: 40px;
    }

    &::-webkit-scrollbar-track {
      background: $box-gray;
      border-radius: 10px;
    }

    .title_container {
      margin-top: 220px;
    }
  }

  .subscriber_title {
    display: flex;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: center;
    gap: 10px;

    p {
      font-size: 30px;
      font-weight: 700;
      line-height: 30px;
      color: #111;
    }
  }

  .between {
    @include justi-between;

    p {
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 700;

      .next {
        display: block;
        font-size: 13px;
        font-weight: 300;
      }
    }

    .cancle_btn {
      cursor: pointer;
    }
  }
}

.info_box {
  display: flex;
  gap: 50px;
  margin-top: 20px;

  .image_container {
    position: relative;
    display: inline-block;

    img {
      width: 380px;
      border-top-right-radius: 60px;
      height: 559px;
    }

    .circle_icon {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 80px;
      height: 80px;
    }

    .ranking_number {
      position: absolute;
      top: 37px;
      left: 38px;
      font-size: 17px;
      color: white;
      font-weight: bold;
      z-index: 2;
    }
  }
}

@media (max-width: 768px) {
  dialog {
    max-width: 340px;
    height: auto;
    max-height: 98vh;
    padding: 50px 10px 50px 24px;
    overflow-y: auto;

    img {
      width: 281px;
      border-top-right-radius: 60px;
    }

    .content {
      padding-right: 15px;
      overflow-y: auto;
      max-height: 80vh;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: unset;
      }

      &::-webkit-scrollbar-track {
        border-radius: unset;
      }

      .between {
        p {
          font-size: 15px;
        }
      }

      .info_box {
        flex-direction: column;
      }
      .title_container {
        margin-top: 0px;
      }
    }
  }

  .info_box {
    .image_container {
      img {
        width: 280px !important;
        height: 407px !important;
      }

      .circle_icon {
        width: 80px;
        height: 80px;
        top: 5px !important;
        left: 0px !important;
      }

      .ranking_number {
        font-size: 17px !important;
        top: 32px !important;
        left: 28px !important;
      }
    }
  }
}
