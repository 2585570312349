@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
    @include justi-center;
    width: 100%;
    height: 100%;
    padding: 200px 100px;
    box-sizing: border-box;
    background: url("../../../assets/svg/eventBackground.svg") no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;

    .content_container {
        @include justi-align-center;
        width: 100%;
        max-width: 1800px;
        gap: 30px;

        .page_navigation {
            @include flex-column;
            justify-content: flex-end;
            height: 100%;
            min-width: 360px;
            gap: 10px;
            padding: 405px 60px 60px 60px;
            box-sizing: border-box;
            border-top-right-radius: 120px;
            background: $linear-gradient;

            .subtitle {
                display: flex;
                color: rgba(255, 255, 255, 0.30);
                font-family: Poppins;
                font-size: 20px;
                font-weight: 600;
                line-height: 20px;
            }
    
            .navigation_main {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 20px;
    
                .page_buttons {
                    display: flex;
                    gap: 40px;
                    color: #ffffff;
    
                    .icons {
                        cursor: pointer;
                    }
                }
            }
    
            .title {
                color: #ffffff;
                font-size: 36px;
                font-weight: 700;
            }
        }
    
        .swiper_container {
            max-width: 80%;
        }
    }
}

@media (max-width: 768px) {
    .section_container {
        position: relative;
        padding: 60px 0px;

        .content_container {

            .page_navigation {
                justify-content: flex-start;
                position: absolute;
                width: 65vw;
                height: 45vw;
                min-width: 250px;
                top: 10%;
                left: 0px;
                padding: 30px 20px 100px 20px;
                border-top-right-radius: 10vw;

                .navigation_main {
                    @include justi-between;
                    flex-direction: row;
                    align-items: center;
                    width: auto;
                    max-height: 40px;

                    .title {
                        @include align-center;
                        min-width: 115px;
                        font-size: 25px;
                    }

                    .page_buttons {
                        @include justi-align-center;
                        gap: 20px;

                        .icons {
                            width: 24px;
                        }
                    }
                }

                .subtitle {
                    font-size: 13px;
                }
            }

            .swiper_container {
                height: auto;
                max-width: 95vw;
                margin-top: calc(10% + 100px);
            }
        }
    }
}