@import "/src/color.scss";
@import "/src/common.scss";

.section {
  background-color: $light-gray;
  padding: 50px;
  margin-top: 50px;

  p {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;

    .gray {
      color: $deco-gray;
      margin-left: 10px;
    }
  }

  .boxes {
    @include justi-between;
  }
}

@media (max-width: 768px) {
  .section {
    padding: 20px;

    p {
      font-size: 15px;
      margin-bottom: 10px;
    }

    .boxes {
      @include flex-column;
      gap: 10px;
    }
  }
}
