@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
  @include justi-center;
  width: 100%;
  height: 100%;
  padding: 200px;
  box-sizing: border-box;
  background: url("../../../assets/svg/analyzeBackground.svg") no-repeat;
  background-size: cover;
  background-position: bottom right;
  user-select: none;
  overflow: hidden;

  .content_container {
    @include justi-center;
    gap: 100px;

    .title {
      @include flex-column;
      color: #ffffff;
      font-family: Poppins;
      font-size: 120px;
      font-weight: 700;
      line-height: 130px;
    }
  }

  .button_container {
    @include justi-align-center;
    flex-wrap: wrap;
    width: 50%;
    gap: 40px;

    .card {
      position: relative;
      width: 419px;
      height: 440px;
      perspective: 1000px;
      box-sizing: border-box;

      .card_inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s ease;
        transform-style: preserve-3d;
        border-radius: inherit;
      }

      &:hover .card_inner {
        transform: rotateY(180deg);
      }

      .card_front,
      .card_back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #ffffff;
        padding: 40px;
        box-sizing: border-box;
        border-radius: inherit;
      }

      .card_front {
        background: rgba(255, 255, 255, 0);
        span {
          margin-top: 24px;
        }
      }

      .card_back {
        background: rgba(255, 255, 255, 0.14);
        transform: rotateY(180deg);
        span {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }

      img {
        width: 60%;
        height: 150px;
      }

      span {
        color: #ffffff;
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        text-align: center;
      }

      p {
        color: #ffffff;
        font-size: 19px;
        text-align: center;
        line-height: 30px;
      }
    }

    // 각 카드의 테두리 반경 유지
    .card:nth-child(1) .card_inner,
    .card:nth-child(1) .card_front,
    .card:nth-child(1) .card_back {
      border-bottom-left-radius: 100px;
    }

    .card:nth-child(2) .card_inner,
    .card:nth-child(2) .card_front,
    .card:nth-child(2) .card_back {
      border-top-right-radius: 100px;
      margin-top: 20px;
    }

    .card:nth-child(3) .card_inner,
    .card:nth-child(3) .card_front,
    .card:nth-child(3) .card_back {
      border-bottom-right-radius: 100px;
      margin-top: 10px;
    }

    .card:nth-child(4) .card_inner,
    .card:nth-child(4) .card_front,
    .card:nth-child(4) .card_back {
      border-top-left-radius: 100px;
      margin-top: 20px;
    }
  }
}

// 반응형 적용
@media (max-width: 1600px) {
  .section_container {
    padding: 50px;
  }

  .content_container {
    flex-direction: column;
    align-items: center;

    .button_container {
      width: 100%;
      gap: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .section_container {
    padding: 50px 20px;

    .content_container {
      flex-direction: column;
      align-items: center;

      .title {
        font-size: 50px;
        font-weight: 700;
        line-height: 60px;
      }

      .button_container {
        flex-direction: column;
        gap: 24px;

        .card {
          width: 80%;
          max-width: 400px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section_container {
    padding: 60px 10%;

    .content_container {
      flex-direction: column;
      align-items: center;
      gap: 25px;

      .title {
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
      }

      .button_container {
        flex-direction: column;
        gap: 24px;

        .card {
          width: 335px;
          height: 282px;
        }
        .card_front,
        .card_back {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border: 1px solid #ffffff;
          padding: 40px 24px;
          box-sizing: border-box;
          border-radius: inherit;
        }

        // 각 카드의 테두리 반경 유지
        .card:nth-child(1) .card_inner,
        .card:nth-child(1) .card_front,
        .card:nth-child(1) .card_back {
          border-bottom-left-radius: 50px;
        }

        .card:nth-child(2) .card_inner,
        .card:nth-child(2) .card_front,
        .card:nth-child(2) .card_back {
          border-top-right-radius: 50px;
          margin-top: 0px;
        }

        .card:nth-child(3) .card_inner,
        .card:nth-child(3) .card_front,
        .card:nth-child(3) .card_back {
          border-bottom-right-radius: 50px;
          margin-top: 0px;
        }

        .card:nth-child(4) .card_inner,
        .card:nth-child(4) .card_front,
        .card:nth-child(4) .card_back {
          border-top-left-radius: 50px;
          margin-top: 0px;
        }

        img {
          width: 110px;
          height: 100px;
        }

        span {
          font-size: 20px;
          margin-top: 14px !important;
          margin-bottom: 14px !important;
        }

        p {
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}
