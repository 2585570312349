@import "/src/common.scss";
@import "/src/color.scss";

.notice_container {
    @include justi-align-center;
    gap: 10px;
    padding: 12px 0px;
    box-sizing: border-box;
    background-color: $black;
    color: #ffffff;

    span {
        color: #ffffff;
        line-height: 26px;

        &:nth-child(1) {
            font-size: 14px;
            font-weight: 700;
        }

        &:nth-child(2) {
            font-size: 12px;
        }
    }
}

@media (max-width: 768px) {
    .notice_container {
        padding: 7px;
    }
}