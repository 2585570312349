$black: #111111;
$very-dark-gray: #333333;
$dark-gray: #454545;
$navy-gray: #2b313b;
$deco-gray: #6b7280;
$box-gray: #e8e8e8;
$border-gray: #e3e3e3;
$scroll-gray: #b6b6b6;
$text-gray: #8a8a8a;
$light-gray: #f8f8f8;
$footer-gray: #616c80;
$dark-orange: #d68f41;
$active-orange: #ee8b1d;
$light-orange: #fffcf2;
$light-blue: #7cb6d8;
$lilac: #cb70ca;
$violet: #8878da;
$linear-gradient: linear-gradient(279deg, #fb6714 9.27%, #d68f41 95.19%);
$linear-gradient-red: linear-gradient(279deg, #f31449 9.27%, #fb6714 95.19%);
$linear-gradient-100: linear-gradient(279deg, #fb6714 9.27%, #d68f41 95.19%);
