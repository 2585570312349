@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
    @include flex-column-center;
    width: 100%;
    height: 600px;
    padding: 200px 0px;
    box-sizing: border-box;
    background: url("../../../assets/svg/newsletterBackground.svg") no-repeat;
    background-size: cover;
    overflow: hidden;

    .content_container {
        @include flex-column-center;
        width: 100%;
        gap: 40px;
        padding: 200px 0px;
    
        .title_container {
            @include flex-column;
            align-items: center;
            gap: 20px;
        
            .title {
                color: #ffffff;
                font-size: 60px;
                font-weight: 700;
                line-height: 48px;
            }
        
            .description {
                color: #ffffff;
                font-size: 20px;
                line-height: 26px;
                text-align: center;

                .line_break {
                    display: none;
                }
            }
        }
        
        .input_container {
            @include justi-align-center;
            width: 100%;
            gap: 15px;
        
            input {
                width: 500px;
                height: 100%;
                gap: 10px;
                padding-left: 30px;
                box-sizing: border-box;
                border-radius: 48px;
                background-color: rgba(255, 255, 255, 0.30);
                backdrop-filter: blur(2px);
                color: #ffffff;
                font-size: 20px;
                border: none;
            }
        
            input::placeholder {
                color: #ffffff;
                font-size: 18px;
            }
        
            button {
                @include justi-align-center;
                width: 135px;
                height: 55px;
                gap: 10px;
                padding: 20px;
                box-sizing: border-box;
                border: none;
                border-radius: 48px;
                background: $linear-gradient;
                color: #ffffff;
                font-size: 18px;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 768px){
    .section_container {
        height: 359px;
        padding: 100px 20px;

        .content_container {
            gap: 30px;

            .title_container {
                gap: 14px;
                text-align: center;

                .title {
                    font-size: 24px;
                }

                .description {
                    font-size: 15px;

                    .line_break {
                        display: inline;
                    }
                }
            }
        }

        .input_container {
            input {
                width: 100%;
                font-size: 13px !important;
            }

            input::placeholder {
                font-size: 13px !important;
            }

            button {
                width: 100px !important;
                height: 30px !important;
                min-width: 100px;
                font-size: 13px !important;
            }
        }
    }
}