@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
    @include flex-column-center;
    gap: 50px;
    padding: 200px 260px;
    box-sizing: border-box;
    background: url("../../../assets/svg/zineBackground.svg") no-repeat;
    background-size: cover;
    background-position: bottom right;
    overflow: hidden;

    .content_container {
        @include justi-align-center;
        width: inherit;
        height: inherit;
        
        .title {
            @include flex-column;
            align-self: stretch;
            width: 250px;
            height: 100%;
            gap: 70px;
            margin-right: 100px;

            span:nth-child(1) {
                @include flex-column;
                width: 223px;
                height: 100%;
                color: #ffffff;
                font-size: 60px;
                font-weight: 700;
                line-height: 151.3%;

                b:nth-child(1) {
                    font-size: 80px;
                }
            }
        }

        .buttons {
            @include flex-column;
            width: 100%;
            height: 100%;
            gap: 20px;
        
            .button_default {
                @include align-center;
                width: inherit;
                height: 55px;
                padding: 12px 30px;
                box-sizing: border-box;
                border: none;
                border-radius: 46px;
                background: rgba(255, 255, 255, 0.05);
                overflow: hidden;
                cursor: pointer;
        
                .bar {
                    width: 0px;
                    height: 1px;
                    background-color: #ffffff;
                    transition-duration: 1s;
                }
            
                .movie_name_default, .movie_name_overflow {
                    width: 100%;

                    p {
                        width: 100%;
                        color: #ffffff;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 26px;
                        text-align: left;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }

                .marquee {
                    display: none;
                    width: 100%;
                    color: $active-orange;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    text-overflow: clip;
                }
        
                &:hover .bar {
                    width: 22px;
                    margin-right: 14px;
                    background-color: $active-orange;
                }

                &:hover .movie_name_default p {
                    color: $active-orange;
                }
        
                &:hover .movie_name_overflow {

                    p {
                        display: none;
                    }
                    
                    .marquee {
                        display: flex;
                        max-width: 140px;
                    }
                }
            }
        }

        .button_active {
            @include align-center;
            width: inherit;
            height: 55px;
            gap: 14px;
            padding: 12px 30px;
            box-sizing: border-box;
            border: none;
            border-radius: 46px;
            background: rgba(255, 255, 255, 0.05);
            overflow: hidden;
            cursor: pointer;
            
            .bar {
                width: 22px;
                height: 1px;
                background-color: $active-orange;
                transition-duration: 1s;
            }
                        
            .movie_name_default, .movie_name_overflow {
                width: 100%;
        
                p {
                    width: 100%;
                    color: $active-orange;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 26px;
                    text-align: left;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }        
                        
            .marquee {
                display: none;
                width: 100%;
                color: $active-orange;
                font-size: 16px;
                font-weight: 700;
                line-height: 26px;
                text-overflow: clip;
            }
                        
        
            .movie_name_overflow{

                p {
                    display: none;
                }
                            
                .marquee {
                    display: flex;
                    max-width: 140px;
                }
            }
        }

        .swiper_container_zine {
            display: flex;
            width: 100%;
            max-width: 1200px;
        }
    }
}

@media (max-width : 1600px) {
    .section_container {
        padding: 20px;

        .content_container {
            width: 100%;
            flex-direction: column;

            .title {
                width: 100%;
                gap: 14px;
                margin-right: 0px;

                span:nth-child(1) {
                    @include justi-align-center;
                    flex-direction: row;
                    width: 100%;

                    b {
                        font-size: 50px !important;
                    }
                }
            }

            .buttons {
                width: 100%;
                flex-direction: row;

                .button_default, .button_active{
                    width: auto;
                    max-width: 200px;

                    p {
                        max-width: 100px;
                    }
                }

                .button_active {
                    width: auto;
                }
            }

            .swiper_container_zine {
                width: 100%;
            }
        }
    }
}

@media (max-width: 768px) {
    .section_container {
        padding: 60px 20px;

        .content_container {

            .buttons {
                .button_default, .button_active {
                    height: 44px;
                }
            }

            .title {
                width: 100%;
                gap: 14px;
                margin-right: 0px;

                span:nth-child(1) {
                    @include justi-align-center;
                    flex-direction: row;

                    b {
                        font-size: 30px !important;
                    }
                }
            }
        }
    }
}