@import "/src/common.scss";
@import "/src/color.scss";

@mixin button_default {
    @include justi-align-center;
    width: 100%;
    height: 100%;
    gap: 10px;
    padding: 9px 20px;
    box-sizing: border-box;
    border: none;
    border-radius: 56px;
    font-size: 14px;
    font-weight: 700;
    line-height: 26px;
    cursor: pointer;
}

.button_container {
    display: "flex";
    justify-content: 'center';

    .button_hover {
        @include button_default;
        background: #F2F2F2;
        color: $black;
    
        &:hover {
            background: $linear-gradient;
            
            span {
                color: #ffffff;
            }
        }
    }
    
    .button_activated {
        @include button_default;
        background: $linear-gradient;
        color: #ffffff;
    }
}


