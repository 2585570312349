@import 'rsuite/dist/rsuite-no-reset.min.css';

.table_container {
    background: #ffffff;
    overflow: hidden;

  }

  .rs-table {
    border-top: 2px solid #111;
  }

  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
    background-color: #f6f6f6 !important;
    
    .rs-table-cell {
      background-color: #f6f6f6 !important;
    }
  }

  .rs-table-cell-group .rs-table-cell-content {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: transparent;
    text-align: center;
    align-items: center;
    font-size: 17px;
  }

  .rs-table-cell-group .rs-table-cell-first .rs-table-cell-content {
    color: #111;
    font-family: Pretendard;
    font-weight: 700;
  } 

  .rs-table-cell-header .rs-table-cell-content {
    overflow: hidden;
    display: flex;
    justify-content: center;
    color: #111 !important;
    justify-items: center;
    font-size: 17px !important;
    font-weight: 700;
  }

  .rs-table-cell-header .rs-table-cell-content:nth-child(1) {
    text-align: center !important;
    justify-content: center !important;
  }

  .rs-table-cell-full-text:hover {
    overflow: visible;
    z-index: 2 !important;
    width: auto !important;
    box-shadow: inset #f6f6f6 0px 0px 2px !important;
  }