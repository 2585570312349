@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
    @include justi-align-center;
    width: 100%;
    height: inherit;
    gap: 100px;
    padding: 200px;
    box-sizing: border-box;
    background: #F8F8F8;
    user-select: none;
    overflow: hidden;

    .content_container {
        @include flex-column-center;
        width: 100%;
        gap: 60px;
    
        .guide {
            min-width: 250px;
            color: $black;
            font-size: 60px;
            font-weight: 700;
            text-align: center;

            .line_break {
                display: none;
            }
        }
    
        .plancards {
            @include justi-between-align-center;
            width: 100%;
            max-width: 1440px;
            min-width: 1300px;
        }
    }
}

@media (max-width: 1440px) {
    .section_container{
        height: inherit;

        .content_container {

            .plancards {
                flex-direction: column;
                gap: 30px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .section_container{

        .content_container {

            .guide {
                
                .line_break {
                    display: inline;
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .section_container{
        padding: 60px 0px;

        .content_container {
            width: 80%;
            gap: 25px;

            .guide {
                font-size: 30px;
            }
        }
    }
}