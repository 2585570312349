@import "/src/common.scss";
@import "/src/color.scss";

.section_container {
  @include flex-column-center;
  gap: 50px;
  padding: 130px 300px;
  box-sizing: border-box;
  background: url("../../../assets/svg/onlineBackground.svg") no-repeat;
  background-size: cover;
  overflow: hidden;

  .title {
    @include flex-column;
    min-width: 100px;
    gap: 30px;

    span {
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 800;
      background: $linear-gradient;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      user-select: none;
    }

    p {
      color: $black;
      font-size: 60px;
      font-weight: 800;
      text-align: center;
      user-select: none;
    }
  }

  .table {
    display: flex;
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  .section_container {
    padding: 100px 0px;
  }
}

@media (max-width: 768px) {
  .section_container {
    height: 1579px;

    .title {
      gap: 15px;

      span {
        font-size: 15px;
      }

      p {
        font-size: 30px;
      }
    }
  }
}
