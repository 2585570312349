@import "/src/color.scss";
@import "/src/common.scss";

.section {
  @include flex-column;
  border-top: 1px solid $box-gray;
  margin-top: 50px;

  h2 {
    font-size: 30px;
    margin-top: 50px;

    .crown_img {
      margin-right: 10px;
    }
  }

  h3 {
    font-size: 24px;
    margin: 50px 0 20px;
  }

  .graph {
    border: 1px solid $box-gray;
    margin-bottom: 20px;
    padding: 20px;
  }

  .boxes {
    @include justi-between;
  }
}

@media (max-width: 768px) {
  .section {
    margin-top: 40px;

    h2 {
      font-size: 20px;
      margin-top: 40px;

      .crown_img {
        width: 16px;
        height: 13px;
      }
    }

    h3 {
      font-size: 15px;
      margin: 40px 0 10px;
    }

    .graph {
      padding: 14px;
      margin-bottom: 10px;

      img {
        width: 253px;
        height: 90px;
      }
    }

    .boxes {
      @include flex-column;
      gap: 10px;
    }
  }
}
