@import "/src/common.scss";
@import "/src/color.scss";

.footer_container {
    display: flex;
    width: 100%;
    height: inherit;
    padding: 50px 100px;
    box-sizing: border-box;
    background: $black;
    overflow: hidden;

    .content_container {
        @include justi-center;
        width: 100%;
        gap: 20px;
        
        div {
            @include flex-column;
        }
    
        div:nth-child(2){
            margin-right: 100px;
            color: #ffffff;
            font-family: Poppins;
            font-size: 15px;
            font-weight: 600;
        }
    
        div:nth-child(3), div:nth-child(4){
            color: $footer-gray;
            font-size: 12px;
            line-height: 20px;
        }

        .underline {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

@media (max-width: 768px) {
    .footer_container {
        padding: 50px 20px;
        
        .content_container {
            flex-direction: column;
        }
    }
}