@import "/src/color.scss";
@import "/src/common.scss";

.box {
  border: 1px solid $box-gray;
  width: 387px;
  height: 206px;
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;

  div {
    @include align-center;
    gap: 20px;
    padding-bottom: 20px;

    .circle {
      @include justi-align-center;
      width: 70px;
      height: 70px;
      background-color: $light-gray;
      font-size: 30px;
      color: white;
      border-radius: 50px;
    }

    .data {
      @include flex-column;
      font-size: 15px;
      font-weight: 500;
      color: $text-gray;

      span {
        @include align-center;
        font-size: 24px;
        font-weight: 700;
        font-family: "Poppins";
        color: $black;
      }
    }
  }

  .between {
    @include justi-between;
    border-top: 1px solid $box-gray;
    padding-top: 20px;
    font-size: 15px;
    color: $deco-gray;
  }

  .progressBar {
    display: block;
    width: 100%;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 4px;
    padding: 0px;
  }

  .progress {
    display: block;
    height: 100%;
    border-radius: 4px;
    padding: 0px;
  }
}

@media (max-width: 768px) {
  .box {
    width: 281px;
    height: 141px;
    padding: 20px;

    div {
      .circle {
        width: 36px;
        height: 36px;
        font-size: 20px;
      }

      .data {
        font-size: 13px;

        span {
          font-size: 15px;
        }
      }
    }

    .between {
      font-size: 13px;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    .progressBar {
      height: 6px;
    }
  }
}
