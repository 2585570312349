@import "/src/color.scss";
@import "/src/common.scss";

.section {
  @include flex-column;

  h3 {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .graph {
    border: 1px solid $box-gray;
    padding: 20px;
    margin-bottom: 20px;

    img {
      width: 1140px;
    }
  }

  .boxes {
    @include justi-between;
  }
}

@media (max-width: 768px) {
  .section {
    .graph {
      padding: 14px;
      margin-bottom: 10px;

      img {
        width: 255px;
        height: 74px;
      }
    }

    .boxes {
      @include flex-column;
      gap: 10px;
    }
  }
}
