@import "/src/color.scss";
@import "/src/common.scss";

.box {
  border: 1px solid $box-gray;
  width: 387px;
  height: 206px;
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;

  div {
    @include align-center;
    gap: 20px;
    padding-bottom: 20px;

    .circle {
      @include justi-align-center;
      width: 70px;
      height: 70px;
      background-color: $light-gray;
      font-size: 30px;
      color: white;
      border-radius: 50px;
    }

    .data {
      @include flex-column;
      font-size: 15px;
      font-weight: 500;
      color: $text-gray;

      span {
        @include align-center;
        font-size: 24px;
        font-weight: 700;
        font-family: "Poppins";
        color: $black;
      }
    }
  }

  .progressBar {
    width: 100%;
    height: 10px;   
    background-color: #e0e0e0;  
    border-radius: 4px;
    padding: 0px;
    
  }

  .progress {
    height: 10px;
    border-radius: 4px;  
    padding: 0px;
  }
  

  .between {
    @include justi-between;
    border-top: 1px solid $box-gray;
    padding-top: 20px;
    font-size: 15px;
    color: $deco-gray;
  }
}

@media (max-width: 768px) {
  .box {
    width: 281px;
    height: 141px;
    gap: 20px;
    padding: 20px;

    .progressBar {
      width: 100%;
      height: 6px;   
      background-color: #e0e0e0;  
      border-radius: 4px;
      padding: 0px;
      
    }
  
    .progress {
      height: 6px;
      border-radius: 4px;  
      padding: 0px;
    }

    div {
      .circle {
        width: 36px;
        height: 36px;
        font-size: 20px;
      }

      .data {
        font-size: 13px;

        span {
          font-size: 15px;
        }
      }
    }

    .between {
      font-size: 13px;
      padding: 14px 0 14px 0;
    }
  }
}
